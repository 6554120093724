<template>
  <form novalidate @submit.prevent="submit" class="unsubscribe-info">
    <h1 class="unsubscribe-info__title">Отказаться от подписки</h1>
    <p class="unsubscribe-info__desc">
      К сожалению мы не нашли указанный Вами номер
      <strong>{{ data.phone | mask("+# ### #######") }}</strong
      ><br />
      Пожайлуста, заполните форму ниже:
    </p>
    <div class="unsubscribe-info__form">
      <Field
        title="Фамилия"
        v-model="form.lastname"
        :error="formErrors.lastname"
      />
      <Field
        title="Имя"
        v-model="form.firstname"
        :error="formErrors.firstname"
      />
      <Field
        title="Отчество"
        subtitle="(если есть)"
        v-model="form.patronymic"
        :error="formErrors.patronymic"
      />
      <Phone
        title="Телефон"
        subtitle="(для связи)"
        v-model="form.phone_fio"
        :error="formErrors.phone_fio"
      />
      <div class="unsubscribe-info__card-number">
        <Field
          title="Первые 6 цифр карты"
          mask="#### ##"
          valueWithoutMask
          v-model="form.pan_first"
          :error="formErrors.pan_first"
        />
        <Field
          title="Последние 4 цифры"
          mask="####"
          valueWithoutMask
          v-model="form.pan_last"
          :error="formErrors.pan_last"
        />
      </div>
    </div>
    <div class="unsubscribe-info__actions">
      <Button back @click.native="back()"> Назад </Button>
      <Button type="submit"> Продолжить </Button>
    </div>
  </form>
</template>

<script>
import Field from "@index/components/common/field/Field";
import Phone from "@index/components/common/field/Phone";
import Button from "@index/components/gui/button/Button";

import mask from "@index/helpers/string/setMask";

import validation from "@index/mixins/validation";
import Validation from "@index/ext/validation/Validation";

import "./unsubscribe-info.scss";

import store from "@index/store";

import {
  MIN as MIN_VAL,
  MAX as MAX_VAL,
  NAME_EXP,
} from "@index/const/validationValues";
const NAME_RULES = [
  [Validation.EXP, NAME_EXP],
  [Validation.MIN, MIN_VAL],
  [Validation.MAX, MAX_VAL],
];

const NAME_ERROR_MSG = {
  [Validation.EXP]: "Допустимы только русские буквы, дефис и апостроф",
};

import sendUnsubscribe from "@index/api/sendUnsubscribe";

export default {
  name: "UnsubscribeInfo",
  mixins: [validation],
  data() {
    return {
      form: {
        lastname: "",
        firstname: "",
        patronymic: "",
        phone_fio: "",
        pan_first: "",
        pan_last: "",
      },
      formRules: {
        lastname: [Validation.REQUIRED, ...NAME_RULES],
        firstname: [Validation.REQUIRED, ...NAME_RULES],
        patronymic: NAME_RULES,
        phone_fio: [Validation.REQUIRED, Validation.PHONE],
        pan_first: [Validation.REQUIRED, [Validation.MIN, 6]],
        pan_last: [Validation.REQUIRED, [Validation.MIN, 4]],
      },
      customErrors: {
        firstname: NAME_ERROR_MSG,
        lastname: NAME_ERROR_MSG,
        patronymic: NAME_ERROR_MSG,
      },
    };
  },
  computed: {
    info() {
      return this.$route.params.info;
    },
    data() {
      return this.$route.params.data;
    },
  },
  methods: {
    back() {
      this.$router.push({ name: "Unsubscribe" });
    },
    async submit() {
      this.validate();

      if (!this.isValid) return;

      const info = await sendUnsubscribe({
        ...this.form,
        phone_not_found: this.data.phone,
      });

      this.$router.push({
        name: "UnsubscribeMessage",
        params: {
          info,
          data: {
            ...this.form,
            ...this.data,
          },
        },
      });
    },
  },
  components: {
    Field,
    Phone,
    Button,
  },
  filters: {
    mask,
  },
  beforeRouteEnter(to, from, next) {
    const { info, data } = to.params;

    if (info && data) {
      next();
    } else {
      store.commit("application/load", false);
      next({
        name: "Unsubscribe",
      });
    }
  },
};
</script>
